import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Glide from "@glidejs/glide";

import NegociaFacilGray from "../images/icons/clients/negocia-facil-gray.svg";
import NegociaFacilColor from "../images/icons/clients/negocia-facil-color.svg";
import B4BVenturesGray from "../images/icons/clients/b4b-ventures-gray.svg";
import B4BVenturesColor from "../images/icons/clients/b4b-ventures-color.svg";
import ClickBusGray from "../images/icons/clients/clickbus-gray.svg";
import ClickBusColor from "../images/icons/clients/clickbus-color.svg";
import ShopfisioGray from "../images/icons/clients/shopfisio-gray.svg";
import ShopfisioColor from "../images/icons/clients/shopfisio-color.svg";

class Testimonials extends Component {
  ssCarousel () {
    new Glide('.glide-ss', {
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      autoplay: false,
      bound: true,
      breakpoints: {
        770: {
          perView: 1
        },
        1408: {
          perView: 2
        }
      },
      hoverpause: false,
      keyboard: false,
      perView: 3,
      rewind: true,
      type: 'slider'
    }).mount();
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.ssCarousel();
    }
  }

  render () {
    return (
      <>
        <div className="section-h has-text-centered	has-text-left-desktop pad-t-3 mar-t-6 sr-hidden sr-1">
          <h2 className="section-h__2 mar-b-2">
            <FormattedMessage id="home.what_do_our_clients_say" />
          </h2>
        </div>
        
        <div className="section-ss columns is-centered is-multiline pad-t-1 mar-t-3">
          <div className="glide-ss glide">
            <div class="glide__track" data-glide-el="track">
              <div className="glide__slides">
                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <NegociaFacilGray style={{width: "158px"}} />
                        <NegociaFacilColor style={{width: "158px"}} />
                      </div>
                      <p>
                        Nós precisávamos encontrar uma empresa segura para operar os dados dos nossos clientes, com alta disponibilidade, capacidade de envio de um volume grande de e-mails e com segmentação avançada. Tudo isso nós encontramos na ExpertSender. O esforço da equipe para nos manter antenados com as melhores práticas de mercado e encontrar novas oportunidades de comunicação com nossos consumidores tem feito toda a diferença. Estamos muito felizes com essa parceria.
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        José Moniz
                        <span>Head de Negócios<br />Negocia Fácil</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <B4BVenturesGray style={{width: "70px"}} />
                        <B4BVenturesColor style={{width: "70px"}} />
                      </div>
                      <p>
                        Dentro da plataforma da ExpertSender, a criação de réguas de automação e campanhas acionadas são as funcionalidades mais valiosas para nós. É um recurso que não se encontra em qualquer plataforma. A ExpertSender nos ajuda muito a otimizar o tempo. Não é mais preciso fazer solicitações de banco de dados para o meu time, posso rapidamente fazer uma segmentação dentro da própria ferramenta e acelerar o processo.
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        André Lopes
                        <span>Especialista em E-mail Marketing<br />B4B Ventures</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <ClickBusGray style={{width: "158px"}} />
                        <ClickBusColor style={{width: "158px"}} />
                      </div>
                      <p>
                        O design da plataforma e o atendimento da equipe são os dois pontos que mais me agradaram na ExpertSender. Vejo que a curva de aprendizado é relativamente rápida graças ao design intuitivo. Por outro lado, quando sinto alguma dificuldade ou possuo uma requisição mais específica para nossas necessidades nas campanhas recorrentes, por exemplo, sempre consigo achar um canal bem rápido com o suporte, mesmo que por e-mail.
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Daniel Ciardullo
                        <span>Analista de Marketing de Performance CRM<br />ClickBus</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="glide__slide">
                  <div className="column is-full">
                    <div className="card-ss card-ss--shadow card-ss--pad">
                      <div className="card-ss__logo mar-b-2">
                        <ShopfisioGray style={{width: "158px"}} />
                        <ShopfisioColor style={{width: "158px"}} />
                      </div>
                      <p>
                        A ExpertSender entrou com uma parte muito boa para nós: a segmentação. Temos uma inifinidade de possibilidades de segmentação, conseguindo trabalhar de uma forma mais focada em cada tipo de cliente que temos e foi justamente isso o que trouxe mais resultado para nós desde que começamos essa parceria.
                      </p>
                      <div className="card-ss__sign mar-t-2">
                        Eloah Manzoli
                        <span>Analista de CRM<br />ShopFisio</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-glide-el="controls">
              <button class="slider__arrow slider__arrow--prev glide__arrow glide__arrow--prev" data-glide-dir="<" />
              <button class="slider__arrow slider__arrow--next glide__arrow glide__arrow--next" data-glide-dir=">" />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Testimonials
